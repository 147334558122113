@import "abstracts/variables", "abstracts/extends", "abstracts/mixins";


.label {
  display: block;
  @include fonts(20px, 25px, bold, $color-gray-5957);
}

.mat-expansion-panel-header {
  height: 60px;
}
.help--icon {
  width: 32px !important;
  height: 32px !important;
  font-size: 32px;
  color: $color-sakura-95BD;
  margin-right: 12px;
}
.mat-expansion-panel-header-title {
  align-items: center;
  @include fonts(20px, 24px, normal, $color-gray-5957);
  padding: 8px 0;
}
.mat-expansion-panel-content .mat-expansion-panel-body {
  padding: $pd-default;
  background-color: #EFEFEF;
  @include display-flex(center, center);
}
