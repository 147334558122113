@import "abstracts/variables", "abstracts/extends", "abstracts/mixins";
.plan--buttons {
  padding: 0 0 $pd-default * 2;
  @include display-flex(space-between, center);
  @include responsive-mw(480px) {
    padding: 0 0 $pd-default * 2;
    display: block;
  }
  .button {
    width: 45%;
    text-align: center;
    border-radius: 5px;
    padding: $pd-default;
    border: 2px solid $color-sakura-95BD;
    @include fonts(20px, 0, normal, $color-black-1);
    @include responsive-mw(480px) {
      width: 100%;
      padding: $pd-default;
    }
  }
  .button:nth-child(2) {
    @include responsive-mw(480px) {
      margin-top: $mg-default;
    }
  }
}
.address--buttons {
  padding: 0 0 $pd-default * 2;
  @include display-flex(space-between, center);
  @include responsive-mw(480px) {
    padding: 0 0 $pd-default * 2;
    display: block;
  }
  .button {
    width: 45%;
    text-align: center;
    border-radius: 5px;
    padding: $pd-default * 1.35 $pd-default;
    border: 2px solid $color-sakura-95BD;
    @include fonts(20px, 24px, normal, $color-black-1);
    @include responsive-mw(480px) {
      width: 100%;
      padding: $pd-default;
    }
  }
  .button:nth-child(2) {
    @include responsive-mw(480px) {
      margin-top: $mg-default;
    }
  }
}
.payment--button {
  padding-left: $pd-default * 3;
  padding-right: $pd-default * 3;
  @include responsive-mw(960px) {
    padding-left: 0;
    padding-right: 0;
  }
}
.time--button {
  border: 2px solid $color-gray-A8A8 !important;
  border-radius: 20px !important;
  line-height: 22px !important;
  font-size: 17px;
  font-weight: normal;
  color: $color-gray-5957;
  padding: 6px 14px !important;
  margin-left: $mg-default !important;
  white-space: normal !important;
  @include responsive-mw(1024px) {
    padding: 14px 12px !important;
  }
  @include responsive-mw(480px) {
    margin-left: 0 !important;
    font-size: 16px;
    padding: 8px 10px !important;
  }
}
.choosed--convenient--day {
  background-color: $color-sakura-EFF2;
  border-color: $color-sakura-95BD !important;
}
.radio--group {
  @include responsive-mw(480px) {
    display: flex;
    justify-content: space-around;
  }
}
.radio--button {
  margin-right: 5rem;
  @include responsive-mw(480px) {
    margin-right: 0;
  }
  .mat-radio-label-content {
    @include fonts(20px, 24px, normal, $color-gray-5957);
  }
  .mat-radio-outer-circle {
    border-color: $color-sakura-95BD !important;
  }
  .mat-radio-inner-circle {
    background-color: $color-sakura-95BD !important;
  }
}
// .btn-cancel-request {
//   padding: 8px 12px;
//   border: 1px solid $color-gray-5957;
//   border-radius: 10px;
//   background-color: $color-white;
//   @include fonts(14px, 16px, normal, $color-gray-5957);
//   margin-left: 2rem;
//   cursor: pointer;
// }
