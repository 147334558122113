@import "abstracts/variables",
        "abstracts/mixins";

.nav {
  position: fixed;
  top: 0;
  background-color: $color-white;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 1px 8px rgb(0 0 0 / 15%);
  }
.logo {
  width: 100vw;
  @include display-flex(center, center);
  &__icon {
    padding: $pd-default 0;
    width: 164px;
  }
}
