@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as m;

ol > li::marker {
  color: v.$color-sakura-E573;
  font-size: 16px;
  font-weight: bold;
  @include m.responsive-min("md") {
    font-size: 18px;
  }
}
.option .mat-radio-label-content {
  white-space: pre-wrap;
}
.option .mat-radio-container {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  .mat-radio-outer-circle {
    border-color: v.$color-sakura-95BD;
  }
  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    width: 24px;
    height: 24px;
  }
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: v.$color-sakura-E573;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: v.$color-sakura-E573;
}
.higlight-unchecked {
  border: 2px solid red;
  padding: 7px;
}
.higlight-options {
  border: 2px solid red;
  padding: 0 8px;
  border-radius: 10px;
}
