@use "../abstracts/mixins" as m;
@use "../abstracts/variables" as v;
.h1 {
  @include m.fonts(1.563rem, 1.875rem, bold, v.$color-black-25); // 25-30px
  @include m.responsive-min("md") {
    @include m.fonts(2rem, 2.438rem, bold, v.$color-black-25); //32 - 39px
  }
}
.h2 {
  @include m.fonts(1.313rem, 1.625rem, bold, v.$color-black-25); // 21-26px
  @include m.responsive-min("md") {
    @include m.fonts(2rem, 2.438rem, bold, v.$color-black-25); //32 - 39px
  }
}
.h3 {
  @include m.fonts(1.25rem, 1.375rem, bold, v.$color-black-25); // 20px 22px
  margin-bottom: 2rem;
  @include m.responsive-min("md") {
    @include m.fonts(1.563rem, 2rem, bold, v.$color-black-25); // 25px 22px
  }
}

.p {
  @include m.fonts(1.125rem, 1.375rem, normal, v.$color-black-25); // 18px 22px
  margin-bottom: 12px;
  @include m.responsive-min("md") {
    @include m.fonts(
      1.125rem,
      1.375rem,
      normal,
      v.$color-black-25
    ); // 25px 22px
  }
}
.title-contract {
  @include m.fonts(1.25rem, 1.563rem, bold, v.$color-black-25); // 20px 25px
  margin-bottom: 1rem;
  @include m.responsive-min("md") {
    font-size: 1.563rem; // 25px
    line-height: 1.875rem; // 30px
  }
}
.p-contract {
  padding-left: 1rem;
  @include m.fonts(1rem, 1.25rem, normal, v.$color-gray-5957); // 16px 18px
  @include m.responsive-min("md") {
    font-size: 1.125rem; // 25px
    line-height: 1.375rem; // 30px
  }
}
.p-modal-link {
  cursor: pointer;
  @include m.fonts(0.875rem, 1rem, normal, v.$color-gray-5957); // 14px 16px
  @include m.responsive-min("md") {
    font-size: 1rem; // 16px
    line-height: 1.25rem; // 30px
  }
}
.p-total {
  font-weight: bold !important;
}

.p-confirm-date {
  @include m.fonts(1.125rem, 1.438rem, bold, v.$color-sakura-E573); // 18px 23px
  @include m.responsive-min("md") {
    font-size: 1.25rem; // 20px
    line-height: 1.563rem; // 25px
  }
}
.p-investigation-date {
  padding-bottom: 10px;
  @extend .p-confirm-date;
  color: v.$color-gray-5957;
}
.p-investigation-date-normal {
  padding-bottom: 10px;
  @include m.fonts(1.125rem, 1.375, normal, v.$color-gray-5957); // 18px 22px
}
.h4 {
  @extend .p;
  font-weight: bold;
  margin-bottom: 8px;
  @include m.responsive-min("md") {
    margin-right: 1rem;
  }
}
.step-title-bold {
  @extend .h4;
  @include m.responsive-min("md") {
    margin-right: 0;
    font-size: 1.563rem;
    line-height: 1.875rem;
  }
}
.p-notes {
  @include m.fonts(0.938rem, 1.063rem, normal, v.$color-gray-5957);
  @include m.responsive-min("md") {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}
.p-with-icon {
  @include m.display-flex(flex-start, center);
  margin-bottom: 6px;
}
.p-physical-info {
  @include m.fonts(1rem, 1.25rem, bold, v.$color-gray-5957); // 16px 20px
  @include m.responsive-min("md") {
    font-size: 1.125rem;
    line-height: 1.485rem;
  }
}
.ol-li {
  @extend .p-physical-info;
  margin-bottom: 1rem;
  line-height: 1.375rem;
  font-weight: normal;
}
.p-monthly-fee {
  @include m.fonts(1rem, 1.188rem, bold, v.$color-gray-5957); // 16px 19px
  @include m.responsive-min("md") {
    font-size: 1.125rem; // 18px
    line-height: 1.483rem; // 23px
  }
}
.p-important-notice {
  @include m.fonts(0.938rem, 1.063rem, normal, v.$color-gray-5957); // 15px 17px
  @include m.responsive-min("md") {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}
.modal-title {
  @include m.fonts(0.938rem, 1.063rem, bold, v.$color-gray-5957); // 15px 17px
  @include m.responsive-min("md") {
    font-size: 1.125rem;
    line-height: 1.438rem;
  }
}
.modal-contents {
  @include m.fonts(1rem, 1.25rem, normal, v.$color-gray-5957); // 16px 18px
  @include m.responsive-min("md") {
    @include m.fonts(1.125rem, 1.375, normal, v.$color-gray-5957); // 18px 22px
  }
}
.faq {
  @extend .modal-contents;
  font-weight: bold;
  margin-top: 8px;
}
.h2-pseudo {
  text-align: center;
  overflow: hidden;
  @include m.responsive-min("md-x") {
    &::before,
    &::after {
      display: inline-block;
      content: "";
      width: 50%;
      position: relative;
      vertical-align: middle;
      height: 1px;
      background-color: v.$color-gray-A8A8;
    }
    &::before {
      right: 1rem;
      margin-left: -50%;
    }
    &::after {
      left: 1rem;
      margin-right: -50%;
    }
  }
}
