$breakpoints: (
  "sm": 480px,
  "md": 810px,
  "md-x": 970px,
  "lg": 1024px,
);
@mixin display-flex($js, $al_i) {
  display: flex;
  justify-content: $js;
  align-items: $al_i;
}
@mixin fonts($fs, $lh, $fw, $color) {
  font-size: $fs;
  line-height: $lh;
  font-weight: $fw;
  color: $color;
}

@mixin responsive-mw($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin responsive-min($breakpoint) {
  $match-query: map-get($breakpoints, $breakpoint);

  @if $match-query {
    @media (min-width: $match-query) {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}
