@use "../abstracts/variables" as v;
.horizontal-line::after {
  content: "";
  display: inline-block;
  width: 100%;
  margin-right: -100%;
  vertical-align: middle;
  margin-left: 1rem;
  height: 2px;
  background-color: v.$color-gray-A8A8;
}
