@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as m;
.mat-dialog-container {
  position: relative;
  border: 2px solid v.$color-sakura-95BD;
  border-radius: 5px;
  .type-installation__title {
    margin-bottom: 1rem;
    @include m.display-flex(flex-start, center);
  }
  .modal--icon {
    color: v.$color-sakura-95BD;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  .close-btn {
    @extend .modal--icon;
    position: absolute;
    top: 6px;
    right: 3px;
  }
}
