*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body {
  font-family: "Helvetica Neue", sans-serif;
}
// @import "./node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
// @import 'src/sass/variables';

@import "components/inquiry", "components/snackbar", "components/form",
  "components/button", "components/fiber-order",
  "components/installation-schedule";

@import "layout/navbar", "layout/modal";

@import "../sass/base/index";
