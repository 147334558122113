

%horizontal-line {
  display: inline-block;
          content: "";
          width: 50%;
          position: relative;
          vertical-align: middle;
          height: 2px;
          background-color: $color-gray-7070;
          //border: 1px solid $color-gray-7070;
}


%note--address {
  text-align: center;
  margin: $mg-default * 1.5 0;
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  color: $color-sakura-95BD;
}

%label--container {
  display: flex;
  align-items: center;
  margin-bottom: .8rem;
}
