
// padding and margin df
$pd-default: 1rem;
$mg-default: 1rem;

// color
$color-white: #fff;
$color-sakura-EFF2: #FDEFF2;
$color-sakura-96BD:#EE96BD;
$color-sakura-E573:#E573AA;
$color-sakura-95BD:#ED95BD;

$color-black-1: #0A0A0A;
$color-black-25: #282525;

$color-gray-7271: #727171;
$color-gray-7070: #707070;
$color-gray-5957: #595757;
$color-gray-A8A8: #A8A8A8;

$color-blue-light-1: #DFDFFF;
$color-blue-light-2: #9F9FFF;
$color-blue-dark-2: #5F5FFF;
$color-blue-dark-1: #2F2FFF;

$color-gray-BCFO: #C1BFC0;
$color-gray-001F :#1f06061f
