@import "abstracts/variables",
        "abstracts/extends",
        "abstracts/mixins";

.snackbar--success {
  margin: 8px !important;
  background: $color-sakura-EFF2;
  box-shadow: 0 3px 5px -1px rgb(24 24 24 / 20%), 0 6px 10px 0 rgb(98 98 98 / 14%), 0 1px 18px 0 rgb(255 255 255 / 12%);
  .mat-simple-snackbar {
    color: $color-gray-A8A8;
  }
  .mat-simple-snackbar-action {
    color: $color-gray-A8A8;
  }
}
.snackbar--error {
  margin: 8px !important;
  .mat-simple-snackbar {
    color: #fff;
  }
  .mat-simple-snackbar-action {
    color: $color-black-1;
  }
}

.mat-snack-bar-err{
  padding: 24px 7rem !important;
  border-radius: 0 !important;
  background: rgb(255, 128, 128);
  width: 100vw !important;
  margin: 0 !important;
  justify-content: center !important;
  max-width: none !important;
  min-width: none !important;
}
.mat-snack-bar-warning{
  @extend .mat-snack-bar-err;
}
.mat-simple-snackbar {
  font-size: 16px;
  line-height: 23px !important;
  justify-content: center !important;
  color: $color-black-1;
}
.mat-simple-snackbar-action {
  color: $color-black-1;
}
