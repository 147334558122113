@import "abstracts/variables", "abstracts/extends", "abstracts/mixins";

.mat--form {
  width: 30%;
  @include responsive-mw(480px) {
    width: 100%;
    margin-bottom: 1rem;
  }
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    @include responsive-mw(480px) {
      margin-bottom: 1.5rem;
    }
  }
}
.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 5px 2px !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $color-gray-A8A8;
  top: 0 !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-width: 2px !important;
}
.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 !important;
  margin-top: 25px;
}

.highlight {
  border-color: #f44336 !important;
}

.mat-select-value-text {
  color: $color-gray-7070;
  font-size: 18px;
}
.mat--form .mat-form-field-wrapper {
  padding-bottom: 0;
}
.mat--form .mat-form-field-wrapper .mat-form-field-flex {
  padding: 0;
  background-color: $color-white;
  border-radius: 4px;
  align-items: center;
}
.date-time-form .mat-form-field-wrapper {
  padding-bottom: 0;
  .mat-form-field-flex {
    @extend .mat--form;
    width: 100%;
    border-radius: 10px;
  }
}
.phone-number-form .mat-form-field-wrapper {
  padding-bottom: 0;
  .mat-form-field-flex {
    @extend .mat--form;
    width: 100%;
    border-radius: 10px;
    padding: 0.5rem;
  }
}
.mat--form .mat-form-field-infix {
  padding: 1rem 0.5rem;
  border-top: none;
}

.mat--form .mat-form-field-underline {
  display: none;
}
.mat--form .mat-select-arrow-wrapper {
  transform: translateY(-13%);
}
.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
  font-style: 74% !important;
  padding: 0 !important;
  margin-top: 1.6rem;
}
.mat-input-element.mat--input {
  @include fonts(18px, 20px, 400, $color-gray-7070);
}
.mat-select-placeholder {
  @include fonts(18px, 20px, 400, $color-gray-7070);
}
.email--form,
.phone--form,
.minor--form {
  width: 100%;
}
.relationship--form {
  width: 70%;
  @include responsive-mw(480px) {
    width: 100%;
  }
}
.upload__file {
  margin-bottom: $mg-default;
  @include display-flex(space-between, center);
  @include responsive-mw(480px) {
    display: block;
    margin-bottom: $mg-default * 2;
  }
}
.upload__file__label {
  @extend %label--container;
  margin-bottom: 0 !important;
  width: 28%;
  @include responsive-mw(768px) {
    width: 35%;
    padding-bottom: $pd-default;
  }
  @include responsive-mw(480px) {
    width: 100%;
    padding-bottom: $pd-default;
  }
}
.folder-icon {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
  color: $color-gray-A8A8;
  margin-left: $mg-default/2;
  cursor: pointer;
}
.upload__file__form {
  width: 67%;
  @include responsive-mw(480px) {
    width: 100%;
  }
  .mat-error-upload--form {
    color: #f44336;
    font-size: 14px;
    padding-top: 4px;
  }
}
.upload__file__input {
  width: 100%;
  border: 2px solid #a8a8a8;
  border-radius: 5px;
  padding: 0.55rem $pd-default;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label__upload {
    @include fonts(18px, 20px, normal, rgba(0, 0, 0, 0.42));
    display: block;
    width: 100%;
    padding: 0.4rem 0;
    cursor: pointer;
    overflow: hidden;
  }
  .upload {
    width: 40%;
    height: 40px;
    display: none;
  }
}

.upload--passport {
  margin: $mg-default 0 $mg-default * 1.5;
}

.check-box .mat-checkbox-inner-container {
  width: 32px;
  height: 32px;
  @include responsive-mw(480px) {
    width: 28px;
    height: 28px;
  }
}
.check-box {
  display: block !important;
  margin-bottom: $mg-default;
  @include responsive-mw(480px) {
    margin: $mg-default 0 $mg-default * 1.5;
  }
}
.date--checkbox {
  @include responsive-mw(480px) {
    margin: 0 $mg-default 0 0;
  }
}
.check-box .mat-checkbox-frame {
  border-color: $color-sakura-95BD;
}
.mat-checkbox-layout .mat-checkbox-label {
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
  color: $color-gray-7271;
  white-space: normal;
  @include responsive-mw(480px) {
    line-height: 28px;
  }
}
.inv--checkbox {
  .mat-checkbox-inner-container {
    width: 24px;
    height: 24px;
    border-color: $color-gray-A8A8;
  }
  .mat-checkbox-frame {
    border-radius: 5px;
  }
  .mat-checkbox-label {
    margin-left: 8px;
    @include fonts(1rem, 1.25rem, normal, $color-gray-5957);
    @include responsive-min("md") {
      font-size: 1.125rem;
      line-height: 1.375rem;
    }
  }
}
.confirm-isntallation--checkbox {
  @extend .inv--checkbox;
  .mat-checkbox-layout {
    display: flex;
    align-items: center;
  }
  .mat-checkbox-inner-container {
    width: 30px;
    height: 30px;
    border-color: $color-gray-A8A8;
    @include responsive-min("md") {
      width: 40px;
      height: 40px;
    }
  }
  .mat-checkbox-label {
    margin-left: 8px;
    @include fonts(1rem, 1.25rem, bold, $color-gray-5957);
    @include responsive-min("md") {
      font-size: 1.125rem;
      line-height: 1.375rem;
    }
  }
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $color-sakura-95BD;
}
.address--form {
  width: 70%;
  @include responsive-mw(768px) {
    width: 100%;
  }
}
.upload--form {
  width: 70%;
  @include responsive-mw(768px) {
    width: 100%;
  }
}
.mat-select-arrow-wrapper {
  vertical-align: bottom !important;
}
.date--form {
  width: 80%;
  margin-left: auto;
}

.message {
  width: 100%;
  padding: $pd-default;
  border: 2px solid $color-gray-A8A8;
  border-radius: 5px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  @include fonts(20px, 24px, normal, $color-gray-A8A8);
  &::placeholder {
    @include fonts(20px, 24px, normal, $color-gray-A8A8);
  }
}
.date-time-form {
}
